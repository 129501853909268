<template>
  <div>
    <CollapseShow autoplay :info="blocks[0]" key="1" ref="block1" />
    <CollapseShow :info="blocks[1]" type="right" ref="block2" key="2" style="margin-bottom: 100px" />
  </div>
</template>

<script>

  import CollapseShow from '../Block/CollapseShow';
  const blocks = [
    {
      items: [
        {
          active: true,
          title: 'One-stop Solution',
          desc: 'One platform, handling all legal documents for trust services.',
          type: 'img',
          src: require('../../assets/trust/1-1one_stop_solution.jpg')
        },
        {
          active: false,
          title: 'Smart Form Filler',
          desc: 'No more repetitive data entry. One-time collection, used on multiple documents.',
          type: 'img',
          src: require('../../assets/trust/1-2smart_form_filler.jpg')
        },
        {
          active: false,
          title: 'Templates Resources',
          desc: '1000+ Trust templates included.',
          type: 'img',
          src: require('../../assets/trust/1-3strong_smart_resources.jpg')
        },
      ]
    },
    {
      items: [
        {
          active: true,
          title: 'Cloud Document Management',
          desc: 'Unlimited Storage. Access your files everywhere.',
          type: 'img',
          src: require('../../assets/trust/1-4_cloud_collaboration.jpg')
        },
        {
          active: false,
          title: 'Easy Billing',
          desc: 'Charge per service, customize your fee structure.',
          type: 'img',
          src: require('../../assets/trust/1-5easy_biling.gif')
        },
        {
          active: false,
          title: 'Clear Message Board',
          desc: 'Clear and clean presentation of Comments，separately arranged per Article with Pinning Question.',
          type: 'img',
          src: require('../../assets/trust/1-6clear_message_board.jpg')
        },
      ]
    },
  ];
  export default {
    components: { CollapseShow },
    data() {
      return {
        blocks,
      }
    },
    created() {

    },
    methods: {

    }
  }
</script>

<style scoped lang="scss">
  div.immigration-slide-box {
    height: 550px;
  }
  .carousel-item {
    .box {
      margin: 0 auto;
      background-color: #fff;
    }
  }
  #carouselTestControls {
    display: none;
  }
  ul {
    list-style: none;
    padding: 0
  }

  em {
    font-style: normal;
    color: #304881;
    font-size: 20px;
  }

  .table-right {
    text-align: left;
    padding-left: 10px;
  }

  .checkCircle {
    vertical-align: middle;
    display: inline-block;
    width: 14px;
    height: 14px;
    background: url(../../assets/immigration/checkcircle.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat
  }

  .bg-title {
    margin-top: 60px;
  }

  .slide-wrap {
    margin: 100px auto;
    position: relative;
  }

  .slide-box {


    color: #464C56;
    transition: all .3s linear;
    overflow-y: auto;
    height: 100%;

    h4 {
      font-size: 20px;
      margin-bottom: 40px
    }

    p {
      color: #464C56;
    }
  }
  .block3 {
    background: url(../../assets/immigration/middle.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 40px 0 40px;
    position: relative;
  }

  .block3:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 150px;
    height: 300px;
    background: url(../../assets/immigration/circle.png);
    background-size: 100% auto;
    background-repeat: no-repeat;
  }

  ul {
    list-style: none
  }

  .bg-title {
    color: #304881;
    font-size: 28px;
    text-align: center;
    margin: 30px auto;
    text-transform: uppercase
  }

  .cube-lists {
    font-size: 14px;
    margin: 40px auto;
  }

  .line {
    display: block;
    height: 1px;
    border-top: 1px solid #D8D8D8;

  }

  .price-hint {
    font-size: 14px;
    color: #657180;

    span {
      color: #F8822B;
    }
  }

  .pricing {
    background: url(../../assets/immigration/bottom.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-bottom: -1px;
    padding: 30px 0 60px;


    .pricing-hint {
      font-size: 14px;
      color: #fff
    }

    .price {
      overflow: hidden;
      margin: 30px auto 20px;
      color: #F8822B;
      line-height: 42px;

      i:first-child {
        font-size: 18px;
        vertical-align: super
      }

      i:nth-child(2) {
        font-size: 28px;

      }

      i:last-child {
        font-size: 14px;
        color: #657180
      }

      a {
        margin-bottom: 10px;
        display: inline-block;
        background-color: #F8822B;
        color: #fff;
        border-radius: 3px;
        transition: all .3s linear;
        height: 32px;
        line-height: 32px;
        text-align: center;
        font-size: 18px;
        padding: 0px 16px;
      }

      a:hover {
        text-decoration: none;
        color: #fff;
        background-image: linear-gradient(to bottom right, #F76B1C, #F9B54A);
      }
    }

  }

  .pricing-cube {
    margin: 8px 0;
    transition: all .3s linear;
  }

  .pricing-cube:hover {
    transform: translateY(-3px) scale(1.01)
  }

  .pricing-cube>div {
    height: 334px;
    box-sizing: border-box;

    p {
      text-transform: uppercase
    }
  }

  .pricing-wrap {
    li {
      cursor: pointer;
      padding: 0px 8px;
      text-align: center;

      >div {
        i {
          font-style: normal;
        }

        padding: 20px 0;
        border-radius: 8px;
        background-color:#fff;
      }
    }
  }

  .CollapseShow {
    margin: 100px auto
  }

  .slide {
    display: none;
  }
  @media screen and (max-width: 1023px) {
    .testdisplay {
      display: none;
    }
    #carouselTestControls {
      display: block;
      .carousel-control-prev,.carousel-control-next {
        span {
          background-color: rgba(0,0,0,0.5);
          border-radius: 50%;
          width: 30px;
          height: 30px;
          background-size: 50% auto;
        }
      }
    }
    .slide {
      display: inline-block;
    }
  }

  @media (max-width: 577px) {
    .smallgrid {
      padding: 1px;
    }
  }

  @media (max-width: 850px) {

    section.block3 {
      background-size: cover;
      background-position: center;
    }

    .CollapseShow {
      margin: 60px auto
    }

    div.pricing {
      background-size: cover;
    }

    .slide-wrap:before {
      top: -60px;
      left: 0;
      content: '';
      width: 60px;
      height: 60px;
    }

    .slide-wrap:after {
      bottom: -60px;
      right: 0;
      content: '';
      width: 60px;
      height: 60px;
    }

  }


  .casual-div {
    background-color: $theme-grid-backgroud-color;
  }

  table {
    margin: 0 auto;
  }

  .grid-selected {
    background-color: $theme-grid-backgroud-color;
  }

  .board-selected {
    min-height: 24rem;
    background-color: $theme-grid-backgroud-color;
    color: white;
  }

  .padding-right-zero {
    padding-right: 0px;
  }

  .padding-x-zero {
    padding-left: 0px;
    padding-right: 0px;
  }

  .padding-left-zero {
    padding-left: 0px;
  }

  .flex-container {
    display: flex;
    justify-content: center;
  }

  .margin-service {
    margin-top: 80px;
    margin-bottom: 80px;
    padding-top: 80px;
    padding-bottom: 80px;
    background: url("../../assets/nxt/services_bg.png");
    background-size: cover;
  }

  .margin-service-title {
    margin-bottom: 50px;
    color: white;
  }

  button:focus {
    outline: 0;
  }

  .switch-left {
    height: 55px;
    width: 150px;
    background-color: #FFFFFF;
    border: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    font-weight: bolder;
    color: #848384;
    transition: 0.5s;
  }

  .switch-left-selected {
    height: 55px;
    width: 150px;
    background-color: #E5E4E5;
    border: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    font-weight: bolder;
    color: #454445;
  }

  .switch-right {
    height: 55px;
    width: 150px;
    background-color: #FFFFFF;
    border: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    font-weight: bolder;
    color: #848384;
    transition: 0.5s;
  }

  .switch-right-selected {
    height: 55px;
    width: 150px;
    background-color: #E5E4E5;
    border: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    font-weight: bolder;
    color: #454445;
  }


  @keyframes slideUp {
    0% {
      opacity: 0.2;
      position: relative;
      top: 100px
    }

    50% {
      opacity: 0.5;
      position: relative;
      top: 50px
    }

    100% {
      opacity: 1.0;
      position: relative;
      top: 0
    }
  }

  .slideUp {
    animation-name: slideUp;
    animation-duration: 0.7s;
    animation-timing-function: ease-in-out;
  }
</style>