<template>
  <div>
    <HeroTrust/>
    <TrustContent/>
  </div>
</template>

<script>
  import HeroTrust from './Section/HeroTrust'
  import TrustContent from './Section/TrustContent'

  export default {
    components: {HeroTrust, TrustContent}
  }
</script>

<style scoped>
  .al-banner {
    margin-top: -10rem;
    margin-bottom: -45rem;

  }
</style>
